import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

interface ChristmasModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const messages = [
  "Que la magia de la Navidad te llene de amor, paz y alegría.",
  "Que la màgia del Nadal t'òmpliga d'amor, pau i alegria."
];

const ChristmasModal: React.FC<ChristmasModalProps> = ({ isOpen, onClose }) => {
  const [messageIndex, setMessageIndex] = useState(0);

  useEffect(() => {
    if (isOpen) {
      const timer = setInterval(() => {
        setMessageIndex((current) => (current + 1) % messages.length);
      }, 5000);

      return () => clearInterval(timer);
    }
  }, [isOpen]);

  return (
    <AnimatePresence>
      {isOpen && (
        <StyledModal
          isOpen={isOpen}
          onRequestClose={onClose}
          contentLabel="Christmas Message"
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.75)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }
          }}
        >
          <motion.div
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.5, opacity: 0 }}
            transition={{ type: "spring", duration: 0.5 }}
          >
            <ModalContent>
              <ChristmasMessage
                as={motion.p}
                key={messageIndex}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
              >
                {messages[messageIndex]}
              </ChristmasMessage>
              <CloseButton onClick={onClose}>✕</CloseButton>
            </ModalContent>
          </motion.div>
        </StyledModal>
      )}
    </AnimatePresence>
  );
};

const StyledModal = styled(Modal)`
  background: none;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
`;

const ModalContent = styled.div`
  background: linear-gradient(135deg, #ff4b1f 0%, #1fddff 100%);
  padding: 50px 40px;
  border-radius: 20px;
  position: relative;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  max-width: 600px;
  width: 90%;
  text-align: center;
`;

const ChristmasMessage = styled.p`
  font-family: 'Playfair Display', serif;
  color: white;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.4;
  margin: 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  letter-spacing: 0.5px;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
  line-height: 1;
  opacity: 0.8;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }
`;

export default ChristmasModal;
