import React from 'react';
import { Container } from '@mui/material';
import styled from 'styled-components';
import MouTe from '../MouTe';
import gradientBackground from '../../imatges/gradient.jpg';

const MainContainer = styled.main`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-family: 'Futura Book', 'Futura', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-family: 'Futura Book', 'Futura', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const MHomeU_TE = () => {
  return (
    <>
      <MainContainer>
        <Container 
          maxWidth="lg"
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <CenteredContainer>
            <MouTe />
          </CenteredContainer>
        </Container>
      </MainContainer>
    </>
  );
};

export default MHomeU_TE;
