import { createTheme } from '@mui/material/styles';

// Color palette
const colors = {
  turquoise: '#4ACABB',
  mintGreen: '#CBE5C0',
  paleYellow: '#FCF9C2',
  rosyBeige: '#EDC5BD',
  purple: '#84407B',
};

// Responsive font sizes
const getFontSize = (baseSize: number) => ({
  fontSize: `${baseSize * 0.85}rem`, // Mobile
  '@media (min-width:600px)': {
    fontSize: `${baseSize * 0.9}rem`, // Tablet
  },
  '@media (min-width:900px)': {
    fontSize: `${baseSize}rem`, // Desktop
  },
});

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  spacing: (factor: number) => `${0.25 * factor}rem`,
  palette: {
    primary: {
      main: colors.turquoise,
      light: '#6ED5C9',
      dark: '#3A9B8E',
      contrastText: '#fff',
    },
    secondary: {
      main: colors.purple,
      light: '#9E5C96',
      dark: '#6A3363',
      contrastText: '#fff',
    },
    background: {
      default: 'transparent',
      paper: colors.paleYellow,
    },
    text: {
      primary: '#2C2C2C',
      secondary: colors.purple,
    },
    success: {
      main: colors.mintGreen,
      dark: '#A8C9A0',
      light: '#DCEFD5',
    },
    error: {
      main: colors.rosyBeige,
      dark: '#D4A69E',
      light: '#F5D8D2',
    },
  },
  typography: {
    fontFamily: "'Nunito', sans-serif",
    h1: {
      ...getFontSize(2.5),
      fontWeight: 800,
      lineHeight: 1.2,
      marginBottom: '0.5em',
    },
    h2: {
      ...getFontSize(2),
      fontWeight: 700,
      lineHeight: 1.3,
      marginBottom: '0.5em',
    },
    h3: {
      ...getFontSize(1.75),
      fontWeight: 700,
      lineHeight: 1.3,
      marginBottom: '0.5em',
    },
    h4: {
      ...getFontSize(1.5),
      fontWeight: 600,
      lineHeight: 1.4,
      marginBottom: '0.5em',
    },
    h5: {
      ...getFontSize(1.25),
      fontWeight: 600,
      lineHeight: 1.4,
      marginBottom: '0.5em',
    },
    h6: {
      ...getFontSize(1.1),
      fontWeight: 600,
      lineHeight: 1.4,
      marginBottom: '0.5em',
    },
    body1: {
      ...getFontSize(1),
      lineHeight: 1.6,
    },
    body2: {
      ...getFontSize(0.875),
      lineHeight: 1.6,
    },
    button: {
      fontWeight: 600,
      textTransform: 'none',
      ...getFontSize(0.875),
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: '1rem',
          paddingRight: '1rem',
          '@media (min-width:600px)': {
            paddingLeft: '1.5rem',
            paddingRight: '1.5rem',
          },
          '@media (min-width:900px)': {
            paddingLeft: '2rem',
            paddingRight: '2rem',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          color: '#333',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          padding: '0.5rem 1rem',
          '@media (min-width:600px)': {
            padding: '0.75rem 1.5rem',
          },
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          boxShadow: '0 2px 12px rgba(0,0,0,0.05)',
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        container: {
          marginTop: '-1rem',
          marginBottom: '-1rem',
          '@media (min-width:600px)': {
            marginTop: '-1.5rem',
            marginBottom: '-1.5rem',
          },
        },
        item: {
          paddingTop: '1rem',
          paddingBottom: '1rem',
          '@media (min-width:600px)': {
            paddingTop: '1.5rem',
            paddingBottom: '1.5rem',
          },
        },
      },
    },
  },
});

export default theme;
