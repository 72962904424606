import React from 'react';
import { Container, Typography, Grid, Box, Card, Tabs, Tab, Paper } from '@mui/material';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const colors = {
  primary: '#4A4A4A',     // Dark gray for text
  secondary: '#757575',   // Medium gray for secondary text
  accent: '#E0E0E0',     // Light gray for borders and dividers
  background: '#F5F5F5', // Very light gray for backgrounds
  white: '#FFFFFF',      // White for cards and content areas
  highlight: '#2C2C2C'   // Darker gray for emphasis
};

const MainContainer = styled.main`
  width: 100%;
  overflow: hidden;
  font-family: 'Futura Book', 'Futura', sans-serif;
`;

const HeroSection = styled.section`
  position: relative;
  width: 100%;
  min-height: 60vh;
  background: linear-gradient(rgba(44, 44, 44, 0.45), rgba(44, 44, 44, 0.45)),
              url('https://images.unsplash.com/photo-1524178232363-1fb2b075b655?ixlib=rb-4.0.3');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.white};
  padding: 60px 0;
`;

const Section = styled.section<{ bgColor?: string }>`
  padding: 80px 0;
  background-color: ${props => props.bgColor || colors.white};
  position: relative;
`;

const StyledCard = styled(Card)`
  height: 100%;
  padding: 2rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  background-color: ${colors.white} !important;
  border: 1px solid ${colors.accent};
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
`;

const MotionCard = motion(StyledCard);

const ContentBlock = styled(motion.div)`
  margin-bottom: 2rem;
`;

const BlockTitle = styled(Typography)`
  position: relative;
  display: inline-block;
  margin-bottom: 2rem;
  color: ${colors.primary};
  padding: 0.5rem 0;
  background: linear-gradient(to right, ${colors.primary}, ${colors.primary});
  background-size: 0 2px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.5s ease;
  
  &:hover {
    background-size: 100% 2px;
  }
  
  &::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: ${colors.primary};
    left: -16px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    opacity: 0.6;
  }
`;

const StyledTabs = styled(Tabs)`
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.accent};
  .MuiTab-root {
    color: ${colors.secondary};
    &.Mui-selected {
      color: ${colors.primary};
    }
  }
  .MuiTabs-indicator {
    background-color: ${colors.highlight};
  }
`;

const StyledTabPanel = styled(Box)`
  background-color: ${colors.white};
  padding: 2rem;
  border-radius: 0 0 8px 8px;
`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <StyledTabPanel
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </StyledTabPanel>
  );
};

const fadeInUp = {
  initial: { opacity: 0, y: 60 },
  whileInView: { opacity: 1, y: 0 },
  viewport: { once: true },
  transition: { duration: 0.6 }
};

const Formacion = () => {
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const bloques = [
    {
      title: "Autonomía Personal",
      description: "Desarrollo de habilidades para la vida diaria y la independencia personal.",
      icon: "🏠"
    },
    {
      title: "Formación Académica",
      description: "Refuerzo de conocimientos básicos y desarrollo de capacidades de aprendizaje.",
      icon: "📚"
    },
    {
      title: "Formación Pre-laboral",
      description: "Preparación para la integración en el mundo laboral y desarrollo de competencias profesionales.",
      icon: "💼"
    }
  ];

  return (
    <MainContainer>
      <HeroSection>
        <Container maxWidth="lg">
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Typography 
              component="h1" 
              variant="h2" 
              gutterBottom
              sx={{ 
                fontWeight: 700,
                fontSize: { xs: '2.5rem', md: '4rem' },
                marginBottom: '1.5rem',
                textAlign: 'center'
              }}
            >
              Formación Pre-laboral
            </Typography>
            <Typography 
              variant="h5" 
              sx={{ 
                marginBottom: '2rem',
                opacity: 0.9,
                textAlign: 'center',
                maxWidth: '800px',
                margin: '0 auto'
              }}
            >
              Formamos a los jóvenes de Valencia con las herramientas necesarias para su desarrollo personal y profesional, adaptándonos a sus ritmos y necesidades
            </Typography>
          </motion.div>
        </Container>
      </HeroSection>

      <Section bgColor={colors.background}>
        <Container maxWidth="lg">
          <ContentBlock {...fadeInUp}>
            <BlockTitle variant="h3" gutterBottom>
              Nuestro Programa
            </BlockTitle>
            <Typography variant="body1" paragraph sx={{ fontSize: '1.1rem', lineHeight: 1.8, color: colors.primary }}>
              El objetivo de esta formación es dotar a los jóvenes de un itinerario pre-laboral que fomente la autonomía personal 
              y la integración social, así como su capacidad de inserción en el mundo laboral. El programa está diseñado para 
              adaptarse a las necesidades y ritmos de los participantes.
            </Typography>
          </ContentBlock>

          <Grid container spacing={4} sx={{ marginTop: 4 }}>
            {bloques.map((bloque, index) => (
              <Grid item xs={12} md={4} key={index}>
                <MotionCard
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.2 }}
                >
                  <Typography variant="h1" gutterBottom sx={{ fontSize: '3rem', textAlign: 'center' }}>
                    {bloque.icon}
                  </Typography>
                  <Typography variant="h5" gutterBottom sx={{ textAlign: 'center', color: colors.primary }}>
                    {bloque.title}
                  </Typography>
                  <Typography variant="body1" sx={{ textAlign: 'center', color: colors.secondary }}>
                    {bloque.description}
                  </Typography>
                </MotionCard>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Section>

      <Section bgColor={colors.white}>
        <Container maxWidth="lg">
          <Paper elevation={3} sx={{ borderRadius: 2, overflow: 'hidden' }}>
            <StyledTabs
              value={tabValue}
              onChange={handleTabChange}
              centered
              sx={{
                '& .MuiTab-root': {
                  fontSize: '1.1rem',
                  textTransform: 'none',
                  minHeight: 64,
                }
              }}
            >
              <Tab label="Autonomía Personal" />
              <Tab label="Formación Académica" />
              <Tab label="Formación Pre-laboral" />
            </StyledTabs>

            <TabPanel value={tabValue} index={0}>
              <Typography variant="h5" gutterBottom sx={{ color: colors.primary }}>
                Autonomía en la vida diaria
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" paragraph sx={{ color: colors.primary }}>
                    • Higiene personal<br />
                    • Organización del hogar<br />
                    • Uso de transportes<br />
                    • Gestión económica básica<br />
                    • Alimentación equilibrada
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" paragraph sx={{ color: colors.primary }}>
                    <strong>Habilidades sociales:</strong><br />
                    • Resolución de conflictos<br />
                    • Gestión de las emociones<br />
                    • Comunicación efectiva<br />
                    • Relaciones interpersonales
                  </Typography>
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              <Typography variant="h5" gutterBottom sx={{ color: colors.primary }}>
                Áreas de formación académica
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" paragraph sx={{ color: colors.primary }}>
                    <strong>Lectoescritura:</strong><br />
                    • Comprensión lectora<br />
                    • Redacción básica<br />
                    • Expresión oral y escrita
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" paragraph sx={{ color: colors.primary }}>
                    <strong>Matemáticas:</strong><br />
                    • Operaciones básicas<br />
                    • Medidas y porcentajes<br />
                    • Resolución de problemas
                  </Typography>
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={tabValue} index={2}>
              <Typography variant="h5" gutterBottom sx={{ color: colors.primary }}>
                Preparación laboral
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" paragraph sx={{ color: colors.primary }}>
                    <strong>Habilidades laborales:</strong><br />
                    • Trabajo en equipo<br />
                    • Organización de tareas<br />
                    • Uso de herramientas específicas
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" paragraph sx={{ color: colors.primary }}>
                    <strong>Búsqueda de empleo:</strong><br />
                    • Elaboración de CV<br />
                    • Preparación de entrevistas<br />
                    • Uso de plataformas laborales
                  </Typography>
                </Grid>
              </Grid>
            </TabPanel>
          </Paper>
        </Container>
      </Section>

      <Section bgColor={colors.background}>
        <Container maxWidth="lg">
          <ContentBlock {...fadeInUp}>
            <BlockTitle variant="h3" gutterBottom>
              Disciplinas Opcionales
            </BlockTitle>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom sx={{ color: colors.primary }}>
                  Actividades complementarias:
                </Typography>
                <Typography variant="body1" paragraph sx={{ fontSize: '1.1rem', lineHeight: 1.8, color: colors.primary }}>
                  • Actividades de ocio inclusivo<br />
                  • Refuerzo escolar personalizado<br />
                  • Talleres de habilidades artísticas<br />
                  • Producción audiovisual
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom sx={{ color: colors.primary }}>
                  Metodología:
                </Typography>
                <Typography variant="body1" paragraph sx={{ fontSize: '1.1rem', lineHeight: 1.8, color: colors.primary }}>
                  Las actividades se diseñan con un enfoque dinámico y participativo, garantizando una experiencia 
                  enriquecedora y motivadora que complementa la formación principal.
                </Typography>
              </Grid>
            </Grid>
          </ContentBlock>
        </Container>
      </Section>
    </MainContainer>
  );
};

export default Formacion;
