import React from 'react';
import { Container, Typography, Grid, Box, Card, Button } from '@mui/material';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const colors = {
  primary: '#4A4A4A',
  secondary: '#757575',
  accent: '#E0E0E0',
  background: '#F5F5F5',
  white: '#FFFFFF',
  highlight: '#2C2C2C'
};

const MainContainer = styled.main`
  width: 100%;
  overflow: hidden;
  font-family: 'Futura Book', 'Futura', sans-serif;
`;

const HeroSection = styled.section`
  position: relative;
  width: 100%;
  min-height: 70vh;
  background: linear-gradient(rgba(44, 44, 44, 0.45), rgba(44, 44, 44, 0.45)),
              url('https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?ixlib=rb-4.0.3');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.white};
  padding: 60px 0;
`;

const Section = styled.section<{ bgColor?: string }>`
  padding: 80px 0;
  background-color: ${props => props.bgColor || colors.white};
  position: relative;
`;

const StyledCard = styled(motion.div)`
  background: ${colors.white};
  border-radius: 16px;
  padding: 2rem;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  height: 100%;
`;

const HighlightText = styled(Typography)`
  color: ${colors.primary};
  position: relative;
  display: inline-block;
  
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: ${colors.primary};
    transform-origin: bottom right;
    transform: scaleX(0);
    transition: transform 0.5s ease;
  }
  
  &:hover::after {
    transform-origin: bottom left;
    transform: scaleX(1);
  }
`;

const fadeInUp = {
  initial: { opacity: 0, y: 60 },
  animate: { 
    opacity: 1, 
    y: 0,
    transition: {
      duration: 0.6,
      ease: "easeOut"
    }
  }
};

const staggerContainer = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
};

const FPBasica = () => {
  const features = [
    {
      title: "Programa Homologado",
      description: "Certificado oficial por la Consellería de Educación, Cultura y Deporte",
      icon: "🎓"
    },
    {
      title: "Edad: 16-24 años",
      description: "Formación especializada para jóvenes que buscan desarrollarse profesionalmente",
      icon: "👥"
    },
    {
      title: "Servicios Administrativos",
      description: "Especialización en operaciones de grabación y tratamiento de datos",
      icon: "💼"
    }
  ];

  return (
    <MainContainer>
      <HeroSection>
        <Container maxWidth="lg">
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Typography 
              component="h1" 
              variant="h2" 
              gutterBottom
              sx={{ 
                fontWeight: 700,
                fontSize: { xs: '2rem', sm: '2.5rem', md: '4rem' },
                marginBottom: { xs: '1rem', md: '1.5rem' },
                textAlign: 'center',
                lineHeight: { xs: 1.2, md: 1.3 }
              }}
            >
              FP Básica Adaptada
            </Typography>
            <Typography 
              variant="h5" 
              sx={{ 
                marginBottom: '2rem',
                opacity: 0.9,
                textAlign: 'center',
                maxWidth: '800px',
                margin: '0 auto',
                fontSize: { xs: '1.1rem', sm: '1.25rem', md: '1.5rem' },
                lineHeight: { xs: 1.4, md: 1.6 },
                padding: { xs: '0 1rem', md: 0 }
              }}
            >
              Programa formativo de cualificación básica adaptada en Valencia
            </Typography>
          </motion.div>
        </Container>
      </HeroSection>

      <Section bgColor={colors.background}>
        <Container maxWidth="lg">
          <motion.div
            variants={staggerContainer}
            initial="initial"
            animate="animate"
            viewport={{ once: true }}
          >
            <Grid container spacing={4}>
              {features.map((feature, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <motion.div variants={fadeInUp}>
                    <StyledCard
                      whileHover={{ y: -10 }}
                      transition={{ duration: 0.3 }}
                    >
                      <Typography variant="h1" gutterBottom sx={{ 
                        fontSize: { xs: '2.5rem', md: '3rem' }, 
                        textAlign: 'center',
                        marginBottom: { xs: '0.5rem', md: '1rem' }
                      }}>
                        {feature.icon}
                      </Typography>
                      <Typography variant="h5" gutterBottom sx={{ 
                        textAlign: 'center', 
                        color: colors.primary,
                        fontSize: { xs: '1.25rem', md: '1.5rem' },
                        marginBottom: { xs: '0.5rem', md: '1rem' }
                      }}>
                        {feature.title}
                      </Typography>
                      <Typography variant="body1" sx={{ 
                        textAlign: 'center', 
                        color: colors.secondary,
                        fontSize: { xs: '1rem', md: '1.1rem' },
                        lineHeight: { xs: 1.5, md: 1.6 }
                      }}>
                        {feature.description}
                      </Typography>
                    </StyledCard>
                  </motion.div>
                </Grid>
              ))}
            </Grid>
          </motion.div>
        </Container>
      </Section>

      <Section>
        <Container maxWidth="lg">
          <motion.div
            variants={fadeInUp}
            initial="initial"
            animate="animate"
            viewport={{ once: true }}
          >
            <Grid container spacing={6} alignItems="center">
              <Grid item xs={12} md={6}>
                <HighlightText variant="h3" gutterBottom sx={{
                  fontSize: { xs: '1.75rem', sm: '2rem', md: '2.5rem' },
                  lineHeight: { xs: 1.3, md: 1.4 },
                  marginBottom: { xs: '1rem', md: '1.5rem' }
                }}>
                  ¿Por qué elegir nuestro programa?
                </HighlightText>
                <Typography variant="body1" paragraph sx={{ 
                  color: colors.primary, 
                  fontSize: { xs: '1rem', md: '1.1rem' }, 
                  lineHeight: { xs: 1.6, md: 1.8 },
                  marginBottom: { xs: '1rem', md: '1.5rem' }
                }}>
                  El programa de operaciones de grabación y tratamiento de datos y documentos ya está abierto para todo aquel que quiera presentarse. Se trata de un curso homologado por la Consellería de Educación, Cultura y Deporte.
                </Typography>
                <Typography variant="body1" paragraph sx={{ 
                  color: colors.primary, 
                  fontSize: { xs: '1rem', md: '1.1rem' }, 
                  lineHeight: { xs: 1.6, md: 1.8 },
                  marginBottom: { xs: '1rem', md: '1.5rem' }
                }}>
                  Mou-te inició la formación de las personas con capacitació i oci con este programa, teniendo en cuenta que, tras los cuatro años que pueden permanecer en él, algunos/as de ellos/as necesitarán más tiempo de aprendizaje.
                </Typography>
                <Button 
                  variant="contained" 
                  size="large"
                  sx={{ 
                    mt: 3,
                    backgroundColor: colors.primary,
                    '&:hover': {
                      backgroundColor: colors.highlight
                    }
                  }}
                >
                  ¡Apúntate ahora!
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  component="img"
                  src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3"
                  alt="Estudiantes trabajando"
                  sx={{
                    width: '100%',
                    borderRadius: '16px',
                    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)'
                  }}
                />
              </Grid>
            </Grid>
          </motion.div>
        </Container>
      </Section>

      <Section bgColor={colors.background}>
        <Container maxWidth="lg">
          <motion.div
            variants={staggerContainer}
            initial="initial"
            animate="animate"
            viewport={{ once: true }}
          >
            <Typography variant="h3" gutterBottom sx={{ 
              textAlign: 'center', 
              color: colors.primary, 
              mb: { xs: 4, md: 6 },
              fontSize: { xs: '1.75rem', sm: '2rem', md: '2.5rem' },
              lineHeight: { xs: 1.3, md: 1.4 }
            }}>
              Beneficios del Programa
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <motion.div variants={fadeInUp}>
                  <StyledCard>
                    <Typography variant="h5" gutterBottom sx={{ 
                      color: colors.primary,
                      fontSize: { xs: '1.25rem', md: '1.5rem' },
                      marginBottom: { xs: '0.5rem', md: '1rem' },
                      lineHeight: { xs: 1.3, md: 1.4 }
                    }}>
                      Formación Oficial
                    </Typography>
                    <Typography variant="body1" sx={{ 
                      color: colors.secondary,
                      fontSize: { xs: '1rem', md: '1.1rem' },
                      lineHeight: { xs: 1.5, md: 1.6 }
                    }}>
                      Obtén un certificado oficial cuando termines todos los cursos y hayas superado los exámenes correspondientes.
                    </Typography>
                  </StyledCard>
                </motion.div>
              </Grid>
              <Grid item xs={12} md={4}>
                <motion.div variants={fadeInUp}>
                  <StyledCard>
                    <Typography variant="h5" gutterBottom sx={{ 
                      color: colors.primary,
                      fontSize: { xs: '1.25rem', md: '1.5rem' },
                      marginBottom: { xs: '0.5rem', md: '1rem' },
                      lineHeight: { xs: 1.3, md: 1.4 }
                    }}>
                      Profesorado Experto
                    </Typography>
                    <Typography variant="body1" sx={{ 
                      color: colors.secondary,
                      fontSize: { xs: '1rem', md: '1.1rem' },
                      lineHeight: { xs: 1.5, md: 1.6 }
                    }}>
                      Aprovecha el acompañamiento de profesores entregados y con el conocimiento necesario.
                    </Typography>
                  </StyledCard>
                </motion.div>
              </Grid>
              <Grid item xs={12} md={4}>
                <motion.div variants={fadeInUp}>
                  <StyledCard>
                    <Typography variant="h5" gutterBottom sx={{ 
                      color: colors.primary,
                      fontSize: { xs: '1.25rem', md: '1.5rem' },
                      marginBottom: { xs: '0.5rem', md: '1rem' },
                      lineHeight: { xs: 1.3, md: 1.4 }
                    }}>
                      Oportunidades Laborales
                    </Typography>
                    <Typography variant="body1" sx={{ 
                      color: colors.secondary,
                      fontSize: { xs: '1rem', md: '1.1rem' },
                      lineHeight: { xs: 1.5, md: 1.6 }
                    }}>
                      Accede a un mercado laboral cada vez más inclusivo y abierto a nuevas oportunidades.
                    </Typography>
                  </StyledCard>
                </motion.div>
              </Grid>
            </Grid>
          </motion.div>
        </Container>
      </Section>
    </MainContainer>
  );
};

export default FPBasica;
