import React from 'react';
import { Container, Typography, Button, Grid, Box } from '@mui/material';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const MainContainer = styled.main`
  width: 100%;
  overflow: hidden;
  font-family: 'Futura Book', 'Futura', sans-serif;
`;

const HeroSection = styled.section`
  position: relative;
  width: 100%;
  height: 80vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
              url('https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?ixlib=rb-4.0.3');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
`;

const Section = styled.section<{ bgColor?: string }>`
  padding: 100px 0;
  background-color: ${props => props.bgColor || '#fff'};
  position: relative;
  overflow: hidden;
`;

const Image = styled(motion.img)`
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
`;

const Card = styled(motion.div)`
  background: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  height: 100%;
`;

const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 30px;
  margin-top: 50px;
`;

const StatItem = styled(motion.div)`
  text-align: center;
  padding: 30px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
`;

const TeamMemberCard = styled(motion.div)`
  text-align: center;
  margin-bottom: 30px;
`;

const TeamMemberImage = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-bottom: 20px;
  object-fit: cover;
`;

const fadeInUp = {
  initial: { opacity: 0, y: 60 },
  whileInView: { opacity: 1, y: 0 },
  viewport: { once: true },
  transition: { duration: 0.6 }
};

const scaleIn = {
  initial: { opacity: 0, scale: 0.8 },
  whileInView: { opacity: 1, scale: 1 },
  viewport: { once: true },
  transition: { duration: 0.6 }
};

const Conocenos = () => {
  const stats = [
    { number: '500+', label: 'Personas Ayudadas' },
    { number: '50+', label: 'Programas Activos' },
    { number: '10+', label: 'Años de Experiencia' },
    { number: '100%', label: 'Compromiso' }
  ];

  const team = [
    {
      name: 'María García',
      role: 'Directora Ejecutiva',
      image: 'https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?ixlib=rb-4.0.3'
    },
    {
      name: 'Carlos Rodríguez',
      role: 'Coordinador de Programas',
      image: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3'
    },
    {
      name: 'Ana Martínez',
      role: 'Especialista en Inclusión',
      image: 'https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3'
    }
  ];

  return (
    <MainContainer>
      <HeroSection>
        <Container maxWidth="lg">
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Typography 
              component="h1" 
              variant="h2" 
              gutterBottom
              sx={{ 
                fontWeight: 700,
                fontSize: { xs: '2.5rem', md: '4rem' },
                marginBottom: '1.5rem'
              }}
            >
              Transformando Vidas
            </Typography>
            <Typography 
              variant="h5" 
              gutterBottom
              sx={{ 
                marginBottom: '2rem',
                opacity: 0.9
              }}
            >
              Construyendo un futuro más inclusivo y accesible para todos
            </Typography>
            <Button 
              variant="contained" 
              color="primary" 
              size="large"
              sx={{ 
                padding: '12px 30px',
                fontSize: '1.1rem'
              }}
            >
              Conoce Nuestra Historia
            </Button>
          </motion.div>
        </Container>
      </HeroSection>

      <Section>
        <Container maxWidth="lg">
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}>
              <motion.div {...fadeInUp}>
                <Typography variant="h3" gutterBottom sx={{ fontWeight: 700 }}>
                  Nuestra Misión
                </Typography>
                <Typography variant="body1" paragraph sx={{ fontSize: '1.1rem', lineHeight: 1.8 }}>
                  Nos dedicamos a crear un mundo donde la capacitació i oci no sea un obstáculo sino una 
                  oportunidad para enriquecer nuestra sociedad. Trabajamos incansablemente para promover la 
                  inclusión, la accesibilidad y la igualdad de oportunidades.
                </Typography>
                <Typography variant="body1" sx={{ fontSize: '1.1rem', lineHeight: 1.8 }}>
                  A través de programas innovadores y apoyo personalizado, ayudamos a las personas a alcanzar 
                  su máximo potencial y vivir una vida plena e independiente.
                </Typography>
              </motion.div>
            </Grid>
            <Grid item xs={12} md={6}>
              <Image
                src="https://images.unsplash.com/photo-1582213782179-e0d53f98f2ca?ixlib=rb-4.0.3"
                alt="Nuestra Misión"
                {...scaleIn}
              />
            </Grid>
          </Grid>
        </Container>
      </Section>

      <Section bgColor="#f8f9fa">
        <Container maxWidth="lg">
          <motion.div {...fadeInUp}>
            <Typography 
              variant="h3" 
              align="center" 
              gutterBottom
              sx={{ fontWeight: 700, marginBottom: '3rem' }}
            >
              Nuestro Impacto
            </Typography>
          </motion.div>
          <StatsContainer>
            {stats.map((stat, index) => (
              <StatItem
                key={index}
                initial={{ opacity: 0, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <Typography variant="h3" color="primary" gutterBottom>
                  {stat.number}
                </Typography>
                <Typography variant="h6">
                  {stat.label}
                </Typography>
              </StatItem>
            ))}
          </StatsContainer>
        </Container>
      </Section>

      <Section>
        <Container maxWidth="lg">
          <motion.div {...fadeInUp}>
            <Typography 
              variant="h3" 
              align="center" 
              gutterBottom
              sx={{ fontWeight: 700, marginBottom: '3rem' }}
            >
              Nuestro Equipo
            </Typography>
          </motion.div>
          <Grid container spacing={4} justifyContent="center">
            {team.map((member, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <TeamMemberCard
                  initial={{ opacity: 0, y: 30 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <TeamMemberImage src={member.image} alt={member.name} />
                  <Typography variant="h5" gutterBottom>
                    {member.name}
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary">
                    {member.role}
                  </Typography>
                </TeamMemberCard>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Section>

      <Section bgColor="#f8f9fa">
        <Container maxWidth="lg">
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}>
              <Image
                src="https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?ixlib=rb-4.0.3"
                alt="Únete a Nosotros"
                {...scaleIn}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <motion.div {...fadeInUp}>
                <Typography variant="h3" gutterBottom sx={{ fontWeight: 700 }}>
                  Únete a Nuestra Causa
                </Typography>
                <Typography variant="body1" paragraph sx={{ fontSize: '1.1rem', lineHeight: 1.8 }}>
                  Tu apoyo puede marcar la diferencia en la vida de muchas personas. Juntos podemos 
                  crear un mundo más inclusivo y accesible para todos.
                </Typography>
                <Button 
                  variant="contained" 
                  color="primary" 
                  size="large"
                  sx={{ 
                    padding: '12px 30px',
                    fontSize: '1.1rem',
                    marginTop: '2rem'
                  }}
                >
                  Colabora con Nosotros
                </Button>
              </motion.div>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </MainContainer>
  );
};

export default Conocenos;
