import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import theme from './theme';
import './styles/fonts.css';
import MHomeU_TE from './components/pages/Home';
import Conocenos from './components/pages/Conocenos';
import Formacion from './components/pages/Formacion';
import FPBasica from './components/pages/FPBasica';

// Temporary placeholder components
const Services = () => <div>Services Page</div>;
const Community = () => <div>Community Page</div>;
const Contact = () => <div>Contact Page</div>;

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<MHomeU_TE />} />
            <Route path="/conocemos" element={<Conocenos />} />
            <Route path="/formacion-prelaboral" element={<Formacion />} />
            <Route path="/fp-basica" element={<FPBasica />} />
            <Route path="/services" element={<Services />} />
            <Route path="/community" element={<Community />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
